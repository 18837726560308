.modal-header {
    padding: 0;
  }
  .modal-header .close {
    padding: 10px 15px;
  }
  .modal-header ul {
    border: none;
  }
  .modal-header ul li {
    margin: 0;
  }
  .modal-header ul li a {
    border: none;
    border-radius: 0;
  }
  .modal-header ul li.active a {
    color: #e12f27;
  }
  .modal-header ul li a:hover {
    border: none;
  }
  .modal-header ul li a span {
    margin-left: 10px;
  }
  .modal-body .form-group {
    margin-bottom: 10px;
  }
  